.App {
  /*text-align: center;*/
}

.App-logo {
  
}

 

.App-link {
  color: #09d3ac;
}



.items{
  margin-bottom: 1px;
  padding: 10px;
  background-color: rgba(2, 1, 1, 0.774);
  width: 95%;
  margin-left: 10px;
 }

.placeholder-preview{
  border:3px dashed #c3c3c3;
  text-align: center;
  background-color: rgba(2, 1, 1, 0.774);

  cursor: pointer;
  min-height: 200px;
}

.empty-preview{  
  text-align: center; 
  min-height: 200px;
  padding-top: 80px;
}
.nameFile{
  font-size: 10px;
  width: 100%;
  display: block;
  color:#7a7a7a;
}

.error-file{
  width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.masthead {
  position: relative;
  width: 100%;
  
  color: #fff;
  background: url(resource/bg-pattern.png),#7b4397;
  background: url(resource/bg-pattern.png),linear-gradient(to left,#7b4397,#007bff);
}

.formbox{
  background-color: rgba(2, 1, 1, 0.568);
  
}